import { api } from "../api";
export const addTagTypes = ["lists", "users", "org"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      statusesList: build.query<StatusesListApiResponse, StatusesListApiArg>({
        query: () => ({ url: `/lists/statuses` }),
        providesTags: ["lists"],
      }),
      clientUsersList: build.query<
        ClientUsersListApiResponse,
        ClientUsersListApiArg
      >({
        query: () => ({ url: `/lists/users/clients` }),
        providesTags: ["lists"],
      }),
      consultantsList: build.query<
        ConsultantsListApiResponse,
        ConsultantsListApiArg
      >({
        query: () => ({ url: `/lists/users/consultants` }),
        providesTags: ["lists", "users"],
      }),
      accountManagersList: build.query<
        AccountManagersListApiResponse,
        AccountManagersListApiArg
      >({
        query: () => ({ url: `/lists/users/account_managers` }),
        providesTags: ["lists", "users"],
      }),
      licensesList: build.query<LicensesListApiResponse, LicensesListApiArg>({
        query: () => ({ url: `/lists/licenses` }),
        providesTags: ["lists"],
      }),
      licenseStatusesList: build.query<
        LicenseStatusesListApiResponse,
        LicenseStatusesListApiArg
      >({
        query: () => ({ url: `/lists/license_statuses` }),
        providesTags: ["lists"],
      }),
      topicsList: build.query<TopicsListApiResponse, TopicsListApiArg>({
        query: () => ({ url: `/lists/topics` }),
        providesTags: ["lists"],
      }),
      termItemTypesList: build.query<
        TermItemTypesListApiResponse,
        TermItemTypesListApiArg
      >({
        query: () => ({ url: `/lists/term_item_types` }),
        providesTags: ["lists"],
      }),
      activityTypesList: build.query<
        ActivityTypesListApiResponse,
        ActivityTypesListApiArg
      >({
        query: () => ({ url: `/lists/activity_types` }),
        providesTags: ["lists"],
      }),
      activityActionsList: build.query<
        ActivityActionsListApiResponse,
        ActivityActionsListApiArg
      >({
        query: () => ({ url: `/lists/activity_actions` }),
        providesTags: ["lists"],
      }),
      positionsList: build.query<PositionsListApiResponse, PositionsListApiArg>(
        {
          query: () => ({ url: `/lists/positions` }),
          providesTags: ["lists"],
        },
      ),
      templatesList: build.query<TemplatesListApiResponse, TemplatesListApiArg>(
        {
          query: () => ({ url: `/lists/templates` }),
          providesTags: ["lists"],
        },
      ),
      bookingTypesList: build.query<
        BookingTypesListApiResponse,
        BookingTypesListApiArg
      >({
        query: () => ({ url: `/lists/booking_types` }),
        providesTags: ["lists"],
      }),
      orgsList: build.query<OrgsListApiResponse, OrgsListApiArg>({
        query: () => ({ url: `/lists/users_by_orgs` }),
        providesTags: ["lists", "org", "users"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type StatusesListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    internal_name: string;
    name: string;
    description?: string;
    type:
      | (
          | "all"
          | "session"
          | "all_tasks"
          | "task"
          | "internal_task"
          | "custom_work"
          | "quick_question"
          | null
        )
      | null;
    is_automated?: boolean | null;
    transition_phase:
      | "todo"
      | "in_progress"
      | "action_required"
      | "done"
      | "blocked";
    color:
      | "green"
      | "red"
      | "yellow"
      | "grey"
      | "primary"
      | "tertiary"
      | "secondary"
      | "lightGrey";
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type StatusesListApiArg = void;
export type ClientUsersListApiResponse = /** status 200 list */ {
  list: ({
    id: number;
    email?: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    name: string;
  })[];
  map: {
    [key: string]: number;
  };
};
export type ClientUsersListApiArg = void;
export type ConsultantsListApiResponse = /** status 200 list */ {
  list: ({
    id: number;
    email?: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    name: string;
  })[];
  map: {
    [key: string]: number;
  };
};
export type ConsultantsListApiArg = void;
export type AccountManagersListApiResponse = /** status 200 list */ {
  list: ({
    id: number;
    email?: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    name: string;
  })[];
  map: {
    [key: string]: number;
  };
};
export type AccountManagersListApiArg = void;
export type LicensesListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type LicensesListApiArg = void;
export type LicenseStatusesListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    name: string;
    license_id: number;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type LicenseStatusesListApiArg = void;
export type TopicsListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    internal_name: string;
    name: string;
    description?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type TopicsListApiArg = void;
export type TermItemTypesListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    internal_name: string;
    name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type TermItemTypesListApiArg = void;
export type ActivityTypesListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    internal_name: string;
    name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type ActivityTypesListApiArg = void;
export type ActivityActionsListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    name: string;
    internal_name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type ActivityActionsListApiArg = void;
export type PositionsListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    internal_name: string;
    name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type PositionsListApiArg = void;
export type TemplatesListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type TemplatesListApiArg = void;
export type BookingTypesListApiResponse = /** status 200 list */ {
  list: {
    id: number;
    active?: boolean;
    admin_managed?: boolean;
    booking_method?: string | null;
    color?: string | null;
    description_html?: string | null;
    description_plain?: string | null;
    duration?: number;
    internal_note?: string | null;
    kind?: string;
    name: string;
    pooling_type?: string | null;
    scheduling_url?: string;
    secret?: boolean;
    slug?: string | null;
    type?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  map: {
    [key: string]: number;
  };
};
export type BookingTypesListApiArg = void;
export type OrgsListApiResponse = /** status 200 list */ {
  list: ({
    id: number;
    pipedrive_org_id?: number | null;
    name: string;
    phone?: string | null;
    country_code?: string | null;
    website?: string;
    formatted_address?: string | null;
    address?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zip?: string | null;
    it_info?: string | null;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    internal_notes?: string | null;
    license_status_id?: number | null;
    code?: string;
    seq?: number;
    quickbooks_customer_id?: number | null;
    last_billed?: string | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    users: ({
      id: number;
      email?: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      name: string;
      isInternal: boolean;
    })[];
  })[];
  map: {
    [key: string]: {
      index: number;
      users: {
        [key: string]: number;
      };
    };
  };
};
export type OrgsListApiArg = void;
export const {
  useStatusesListQuery,
  useLazyStatusesListQuery,
  useClientUsersListQuery,
  useLazyClientUsersListQuery,
  useConsultantsListQuery,
  useLazyConsultantsListQuery,
  useAccountManagersListQuery,
  useLazyAccountManagersListQuery,
  useLicensesListQuery,
  useLazyLicensesListQuery,
  useLicenseStatusesListQuery,
  useLazyLicenseStatusesListQuery,
  useTopicsListQuery,
  useLazyTopicsListQuery,
  useTermItemTypesListQuery,
  useLazyTermItemTypesListQuery,
  useActivityTypesListQuery,
  useLazyActivityTypesListQuery,
  useActivityActionsListQuery,
  useLazyActivityActionsListQuery,
  usePositionsListQuery,
  useLazyPositionsListQuery,
  useTemplatesListQuery,
  useLazyTemplatesListQuery,
  useBookingTypesListQuery,
  useLazyBookingTypesListQuery,
  useOrgsListQuery,
  useLazyOrgsListQuery,
} = injectedRtkApi;

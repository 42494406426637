import { yup } from "../../lib";
import {
  GetOrgsApiResponse,
  useCreateOrgMutation,
  useUpdateOrgMutation,
  useDeleteOrgMutation,
  useGetLicensesAndStatusesQuery,
  GetOrgByIdApiResponse,
} from "../../state/rtk-query/state/organization";
import { BaseForm, initValue } from "./BaseForm";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { extractDigits } from "../inputs/InputField";
import {
  InputField,
  PhoneWithCountryCodeField,
  SelectField,
  ArrayField,
} from "./fields";
import { TopLabel } from "./fields/TopLabel";
import { DeReactivateButton } from "../buttons/DeReactivateButton";
import { authSelectors, useSelector } from "../../state";
import { useLazyGetQuickbooksCustomerNamesQuery } from "../../state/rtk-query/state/quickbooks";
import { Button, Grid } from "@mui/material";
import { QuickbooksLoginModal } from "../modal";
import { useList } from "../hooks/useNewLists";

function QuickbooksCustomersDropdownComp({
  quickbooks_customer_id,
}: {
  quickbooks_customer_id?: number;
}) {
  const [getQuickbooksCustomerNames, { data }] =
    useLazyGetQuickbooksCustomerNamesQuery();
  const [open, setOpen] = useState(false);
  if (data) {
    return <SelectField name="quickbooks_customer_id" items={data.data} />;
  }
  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {quickbooks_customer_id
          ? "Edit quickbooks customer"
          : "Link to quickbooks customer"}
      </Button>
      <QuickbooksLoginModal
        open={open}
        handleClose={() => setOpen(false)}
        apiCall={getQuickbooksCustomerNames}
      />
    </>
  );
}

const QuickbooksCustomersDropdown = memo(QuickbooksCustomersDropdownComp);

const qlSchema = yup.object({
  label: yup.string().required("Title is required"),
  link: yup.string().required("URL is required"),
});

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  code: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, "Only letters and numbers")
    .length(4, "Code must be 4 characters")
    .required("Code is required"),
  website: yup.string(),
  address: yup.string(),
  license_id: yup.number().required("License is required"),
  license_status_id: yup
    .number()
    .min(1, "Status is required")
    .required("Status is required"),
  consultant_id: yup.number(),
  sub_consultant_id: yup.number(),
  account_manager_id: yup.number(),
  it_info: yup.string(),
  internal_notes: yup.string(),
  quickbooks_customer_id: yup.number(),
  phone: yup
    .string()
    .test(
      "contains-7-10-digits",
      "Number must contain 7 to 10 digits",
      (value) => {
        if (!value) return true;
        const digits = extractDigits(value || "");
        return digits.length >= 7 && digits.length <= 10;
      },
    ),
  quickLinks: yup.array(qlSchema),
});

interface Props {
  onClose: () => void;
  editOrg?: GetOrgsApiResponse["rows"][0] | GetOrgByIdApiResponse;
}

export const AddOrgForm = ({ onClose, editOrg }: Props) => {
  const isAdmin = useSelector(authSelectors.isAdmin);
  const { currentData: { licenses } = { licenses: [] } } =
    useGetLicensesAndStatusesQuery();

  const [createOrg, { isSuccess }] = useCreateOrgMutation();
  const [updateOrg, { isSuccess: isSuccessUpdate }] = useUpdateOrgMutation();
  const success = isSuccess || isSuccessUpdate;
  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);
  const [deleteOrg] = useDeleteOrgMutation();
  const { id } = editOrg ?? {};

  const license_status_id = useMemo(
    () => initValue(editOrg?.license_status_id),
    [editOrg?.license_status_id],
  );
  const license_id = useMemo(
    () =>
      initValue(
        license_status_id &&
          licenses.find((l) =>
            l.license_statuses.find((ls) => ls.id === license_status_id),
          )?.id,
      ),
    [licenses, license_status_id],
  );

  const initialValues = useMemo(
    () => ({
      name: initValue(editOrg?.name),
      code: initValue(editOrg?.code),
      website: initValue(editOrg?.website),
      phone: initValue(editOrg?.phone),
      country_code: initValue(editOrg?.country_code, "1"),
      address: initValue(editOrg?.address),
      license_id,
      license_status_id,
      consultant_id: initValue(editOrg?.consultant_id),
      sub_consultant_id: initValue(editOrg?.sub_consultant_id),
      account_manager_id: initValue(editOrg?.account_manager_id),
      it_info: initValue(editOrg?.it_info),
      internal_notes: initValue(editOrg?.internal_notes),
      quickbooks_customer_id: initValue(editOrg?.quickbooks_customer_id),
      quickLinks: initValue(editOrg?.organization_links, [
        {
          label: "Admire Academy",
          link: "https://admire.document360.io",
        },
        {
          label: "Admire Community",
          link: "https://community.admirepro.com",
        },
        {
          label: "Admire Unlimited",
          link: "https://client.wvd.microsoft.com",
        },
      ]),
    }),
    [
      editOrg?.account_manager_id,
      editOrg?.address,
      editOrg?.code,
      editOrg?.consultant_id,
      editOrg?.country_code,
      editOrg?.internal_notes,
      editOrg?.it_info,
      editOrg?.name,
      editOrg?.organization_links,
      editOrg?.phone,
      editOrg?.quickbooks_customer_id,
      editOrg?.sub_consultant_id,
      editOrg?.website,
      license_id,
      license_status_id,
    ],
  );

  const handleSubmit = async (values: typeof initialValues) => {
    const { quickbooks_customer_id, quickLinks, ...rest } = values;
    const body = {
      ...rest,
      ...(isAdmin
        ? {
            quickbooks_customer_id: quickbooks_customer_id
              ? Number(quickbooks_customer_id)
              : null,
          }
        : {}),
      quick_links: quickLinks,
    };
    if (id) {
      updateOrg({ orgId: id, body });
    } else {
      createOrg({ body });
    }
  };

  const consultants = useList("consultants");
  const ams = useList("accountManagers");
  const [inactive, setInactive] = useState(!!editOrg?.deleted_at);

  const updateLicense = useCallback(
    (
      setFieldValue: (name: string, value: any) => void,
      values: typeof initialValues,
    ) => {
      const license = licenses.find((l) => l.id === values?.license_id);
      if (!license) return [];
      const currentStatusIsValid = license.license_statuses.find(
        (ls) => ls.id === values?.license_status_id,
      );

      if (!currentStatusIsValid && values?.license_status_id !== -1) {
        setFieldValue("license_status_id", -1);
      }
      return license?.license_statuses ?? [];
    },
    [licenses],
  );

  return (
    <BaseForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onClose={onClose}
      enableReinitialize
      disableEditing={inactive}
      additionalButtons={
        !!id && (
          <DeReactivateButton
            entity="org"
            isActive={!inactive}
            handleClick={(type) => {
              if (type === "de") {
                deleteOrg({ orgId: id });
                onClose();
              } else {
                setInactive(false);
              }
            }}
          />
        )
      }
    >
      {({ setFieldValue, values }) => {
        const licensesStatuses = updateLicense(setFieldValue, values);

        return (
          <Grid container rowSpacing={2} columnSpacing={1.5}>
            <InputField name="name" topLabel md10 xs7 />
            <InputField
              name="code"
              placeholder="ABCD"
              sx={{
                "::placeholder": {
                  fontStyle: "italic",
                },
              }}
              disabled={!!editOrg}
              topLabel={"Org code"}
              md2
              xs5
            />
            <InputField name="website" topLabel />
            <PhoneWithCountryCodeField
              countryCodeName="country_code"
              name="phone"
              topLabel
            />
            <InputField name="address" topLabel md12 />
            <SelectField
              name="license_id"
              items={licenses}
              datatype="number"
              topLabel="License"
            />
            <SelectField
              name="license_status_id"
              items={licensesStatuses}
              datatype="number"
              topLabel="Status"
            />
            <SelectField
              name="consultant_id"
              items={consultants.list}
              topLabel
            />
            <SelectField
              name="sub_consultant_id"
              items={consultants.list}
              topLabel
            />
            <SelectField name="account_manager_id" items={ams.list} topLabel />
            <InputField name="it_info" topLabel="IT info" />
            <InputField name="internal_notes" topLabel="Internal notes" md12 />
            {isAdmin && (
              <Grid item md={12} xs={12}>
                <TopLabel label="Quickbooks customer">
                  <QuickbooksCustomersDropdown
                    quickbooks_customer_id={values.quickbooks_customer_id}
                  />
                </TopLabel>
              </Grid>
            )}

            <ArrayField
              name="quickLinks"
              buttonName="Add Quick Link"
              itemInitialValue={{
                label: "",
                link: "",
                editable: true,
              }}
              label="Quick Links"
            >
              {({ FieldWithDeleteButton }) =>
                values.quickLinks.map((ql, index) => (
                  <Grid
                    item
                    container
                    rowSpacing={1.5}
                    columnSpacing={1.5}
                    key={index}
                  >
                    <InputField
                      name={`quickLinks[${index}].label`}
                      topLabel="Title"
                    />
                    <FieldWithDeleteButton
                      Component={InputField}
                      name={`quickLinks[${index}].link`}
                      topLabel="URL"
                      index={index}
                    />
                  </Grid>
                ))
              }
            </ArrayField>
          </Grid>
        );
      }}
    </BaseForm>
  );
};
